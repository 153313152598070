/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import SkipTheCallTemplate from '@cleverrealestate/clever-components-v2/dist/components/SkipTheCallTemplate';
import usePebblePost from '@cleverrealestate/clever-components-v2/dist/Utils/usePebblePost';
import queryString from 'query-string';
import Layout from '../components/layout';
import SEO from '../components/Seo';

const SkipThePhoneCall = () => {
  const { setPebbleOrderID, setPebbleCustomerEmail } = usePebblePost();
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      const { location } = window;
      const info = queryString.parse(location.search);
      if (info.email && info.phone) {
        setPebbleCustomerEmail(info.email);
        setPebbleOrderID(info.email, info.phone);
      }
    }
  }, []);

  return (
    <Layout
      colorTransitionStart={50}
      shadowTransitionStart={50}
      colorTransitionLength={100}
      shadowTransitionLength={100}
      hideContactUs
    >
      <SEO
        pathname="/skip-the-phone-call/"
        title="Skip the Phone Call"
        meta={[{ name: 'robots', content: 'noindex' }]}
      />

      <SkipTheCallTemplate onFormSubmit={() => navigate('/thanks-for-signing-up-2/?seller')} />
    </Layout>
  );
};

export default SkipThePhoneCall;
